import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";



const login = async (user) => {
  const response = await axios.post(`${base_url}user/login`, user);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};
const getOrders = async (data) => {
  const response = await axios.get(`${base_url}user/getallorders`, data);

  return response.data;
};
const getOrder = async (id) => {
 
  try {
    const response = await axios.get(
      `${base_url}user/getaOrder/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching order:", error);
    throw error; 
  }
};


const updateOrder = async (data) => {
  console.log("data",data);
  
  const response = await axios.put(
    `${base_url}user/updateOrder/${data.id}`, // Updated route to match the backend
    { 
      deliveryBoyId: data.deliveryBoyId, // Pass the delivery boy's ID in the body
      status: data.status // Include the status change
  },   // Pass the delivery boy's ID in the body
    config
  );

  return response.data;
};

const getMonthlyOrders = async (data) => {
  const response = await axios.get(
    `${base_url}user/getMonthWiseOrderIncome`,

    data
  );

  return response.data;
};

const getYearlyStats = async (data) => {
  const response = await axios.get(
    `${base_url}user/getyearlyorders`,

    data
  );

  return response.data;
};

const authService = {
  login,
  getOrders,
  getOrder,
  getMonthlyOrders,
  getYearlyStats,
  updateOrder,
  
};

export default authService;
