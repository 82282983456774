import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";


const getActivities = async (role) => {
  
  const response = await axios.get(`${base_url}activity/role/${role.role}`);
  console.log(response.data)

  return response.data;
};

// Get activities for a specific admin
const getUserActivities = async (id) => {
  const response = await axios.get(`${base_url}activity/admin/${id}`, config);
 
  return response.data.activityLogs; 
};


const activityService = {
  getActivities,
  getUserActivities,
  
};

export default activityService;
