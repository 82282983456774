// src/features/register/registerSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import registerService from './registerService';
import { useRouteLoaderData } from 'react-router-dom';




// Async thunk to handle user registration
export const registerUser = createAsyncThunk(
  'register/registerUser',
  async (userData, thunkAPI) => {
    try {
      return await registerService.register(userData);
      
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  user: null,
  isLoading: false,
  isSuccess: false,   // Add isSuccess flag
  isError: false,     // Add isError flag
  message: '',
  error: null,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetState: (state) => {
      state.user = null;
      state.isLoading = false;
      state.isSuccess = false; // Reset isSuccess flag
      state.isError = false;   // Reset isError flag
      state.message = '';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;  // Reset on pending
        state.isError = false;    // Reset on pending
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;  // Set isSuccess to true on success
        state.isError = false;   // Reset isError
        state.user = action.payload;
        state.message = 'User created successfully! A verification email has been sent.';
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;  // Reset isSuccess on error
        state.isError = true;     // Set isError to true on error
        state.error = action.payload || 'Error creating user.';
        state.message = '';
      });
  },
});

export const { resetState } = registerSlice.actions;

export default registerSlice.reducer;

