import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Userdropdown from "./Userdropdown";
import Userdropdown2 from "./Userdropdown2";
import { useSelector } from "react-redux";
import navimg from "../../assets/img/logo/nav-log.png"
function HeaderT() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const userState = useSelector((state) => state?.auth);
    const dropdownRef = useRef(null);
    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
      };
    
      const handleMouseLeave = () => {
        setIsDropdownOpen(false);
      };
  return (
    <div>
      <div>
        {" "}
        <div>
          {" "}
          <div>
            <div className="bg-red-600">
              {" "}
              <h1 className="protest-guerrilla-regular text-center text-white p-3">
                WIB FASION Delivery App
              </h1>
            </div>
            <div className="container-fluid">
              <div className="main__header--inner position__relative d-flex justify-content-between align-items-center">
                <div className="offcanvas__header--menu__open ">
                  <Link
                    className="offcanvas__header--menu__open--btn"
                    href="delivery"
                    data-offcanvas
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon offcanvas__header--menu__open--svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M80 160h352M80 256h352M80 352h352"
                      />
                    </svg>
                    <span className="visually-hidden">Menu Open</span>
                  </Link>
                </div>
                <div className="text-center">
                  <h1 className="headerimg">
                    <Link
                      className=""
                      to="/delivery"
                    >
                      <img
                        className="main__logo--img"
                        src={navimg}
                        alt="logo-img"
                      />
                    </Link>
                  </h1>
                </div>
                <div className="header__search--widget header__sticky--none d-none d-lg-block"></div>
                <div className="header__account header__sticky--none">
                  <ul className="d-flex">
                    <li className="header__account--items">
                      <div
                        className="relative"
                        ref={dropdownRef}
                        onMouseEnter={handleMouseEnter} // Open dropdown on hover
                        onMouseLeave={handleMouseLeave} // Close dropdown when leaving the icon
                      >
                        {/* Person Icon */}
                        <button className="header__account--btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26.51"
                            height="23.443"
                            viewBox="0 0 512 512"
                            className="svg ml-[23px]"
                          >
                            <path
                              d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={32}
                            />
                            <path
                              d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                              fill="none"
                              stroke="currentColor"
                              strokeMiterlimit={10}
                              strokeWidth={32}
                            />
                          </svg>
                          <span className="header__account--btn__text">
                            My Account
                          </span>
                        </button>

                        {/* Dropdown menu */}
                        {isDropdownOpen && (
                          <div className="absolute right-0 mt-4 shadow-lg rounded-lg overflow-hidden z-40">
                            {userState?.user ? (
                              <Userdropdown /> // Show authenticated dropdown
                            ) : (
                              <Userdropdown2 /> // Show unauthenticated dropdown
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                    <li className="header__account--items">
                      <Link
                        className="header__account--btn minicart__open--btn"
                        to=""
                        data-offcanvas
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26.51"
                          height="23.443"
                          viewBox="0 0 14.706 13.534"
                          className="svg ml-[16px]"
                        >
                          <g transform="translate(0 0)">
                            <g>
                              <path
                                data-name="Path 16787"
                                d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z"
                                transform="translate(0 -463.248)"
                                fill="currentColor"
                              />
                              <path
                                data-name="Path 16788"
                                d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z"
                                transform="translate(-1.191 -466.622)"
                                fill="currentColor"
                              />
                              <path
                                data-name="Path 16789"
                                d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z"
                                transform="translate(-2.875 -466.622)"
                                fill="currentColor"
                              />
                            </g>
                          </g>
                        </svg>
                        <span className="header__account--btn__text">
                          {" "}
                          Orders
                        </span>
                        <span className="items__count">01</span>
                      </Link>
                    </li>
                    <li className="header__account--items d-none d-lg-block"></li>
                  </ul>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderT;
