import React from "react";
import HeaderT from "./HeaderT";
import FooterB from "./FooterB";
function Dhistort() {
  return (
    <div>
      <HeaderT />
      <h1>Delivery History</h1>
      <FooterB />
    </div>
  );
}

export default Dhistort;
