// src/features/resetPassword/resetPasswordSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import resetPasswordService from './ResetPasswordService';

// Async thunk to handle password reset request
export const resetPassword = createAsyncThunk(
  'resetPassword/resetPassword',
  async ({ token, currentPassword, newPassword }, thunkAPI) => {
    try {
        console.log(currentPassword)
      return await resetPasswordService.resetPassword(token, currentPassword, newPassword);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || 'Password reset failed');
    }
  }
);

const initialState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = action.payload.message;
        state.errorMessage = '';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.successMessage = '';
        state.errorMessage = action.payload;
      });
  },
});

export default resetPasswordSlice.reducer;
