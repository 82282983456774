// src/features/Document/DocumentSlice.js

import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import documentService from "./DocumentService";

// Thunks for async actions
export const createDocuments = createAsyncThunk(
  "document/create-documents",
  async (documentData, thunkAPI) => {
    try {
      return await documentService.createDocument(documentData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getDocuments = createAsyncThunk(
  "document/get-documents",
  async (thunkAPI) => {
    try {
      return await documentService.getDocuments();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getDocument = createAsyncThunk(
  "document/get-document",
  async (id, thunkAPI) => {
    try {
      return await documentService.getDocument(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "document/delete-document",
  async (id, thunkAPI) => {
    try {
      return await documentService.deleteDocument(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateDocument = createAsyncThunk(
  "document/update-document",
  async (documentData, thunkAPI) => {
    try {
      return await documentService.updateDocument(documentData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Reset state action
export const resetState = createAction("document/reset-all");

const initialState = {
  documents: [],  // Will store the list of documents
  selectedDocument: null,  // Store a single document's data
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: "",  // To store success/error messages
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handling get documents action
      .addCase(getDocuments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.documents = action.payload;  // Set fetched documents
        state.message = "Documents fetched successfully";
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload || action.error.message;
      })

      // Handling get document by ID action
      .addCase(getDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocument.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.selectedDocument = action.payload;  // Store the selected document
        state.message = "Document fetched successfully";
      })
      .addCase(getDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload || action.error.message;
      })

      // Handling create document action
      .addCase(createDocuments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDocuments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.documents.push(action.payload);  // Add newly created document
        state.message = "Document created successfully";
      })
      .addCase(createDocuments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload || action.error.message;
      })

      // Handling delete document action
      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.documents = state.documents.filter((doc) => doc._id !== action.payload._id);
        state.message = "Document deleted successfully";
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload || action.error.message;
      })

      // Handling update document action
      .addCase(updateDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDocument.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.documents = state.documents.map((doc) =>
          doc._id === action.payload._id ? action.payload : doc  // Update specific document
        );
        state.message = "Document updated successfully";
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload || action.error.message;
      })

      // Reset the state when resetState is dispatched
      .addCase(resetState, () => initialState);
  },
});

export default documentSlice.reducer;
