import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import activityService from './ActivityService';

// Async thunk to fetch all activities
export const getActivities = createAsyncThunk(
  'activity/getActivities',
  async (role, thunkAPI) => {
    try {
      // Pass the role parameter to the service function
      const response = await activityService.getActivities(role);
      return response;
    } catch (error) {
      // Handle error and return a default error message if the response data is unavailable
      return thunkAPI.rejectWithValue(
        error?.response?.data || 'Failed to fetch activities.'
      );
    }
  }
);

// Async thunk to fetch activities for a specific admin
export const getUserActivities = createAsyncThunk(
  'activity/getUserActivities',
  async (adminId, thunkAPI) => {
    try {
      const response = await activityService.getUserActivities(adminId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data || `Failed to fetch activities for admin ID: ${adminId}`
      );
    }
  }
);

// Initial state
const initialState = {
  activityLogs: [], // Store activity logs
  loading: false,   // Loading state
  error: null,      // Error message
};

// Activity slice
const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    resetActivityState: (state) => {
      state.activityLogs = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get all activities
      .addCase(getActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.activityLogs = action.payload;
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch activities.';
      })

      // Get activities for a specific admin
      .addCase(getUserActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.activityLogs = action.payload;
      })
      .addCase(getUserActivities.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || 'Failed to fetch activities for the specified admin.';
      });
  },
});

export const { resetActivityState } = activitySlice.actions;

export default activitySlice.reducer;
