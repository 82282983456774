import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getBlogSubCategories = async () => {
    const response = await axios.get(`${base_url}blogSubcategory/`);
    return response.data;
};

const createSubCategory = async (subcategory) => {
    const response = await axios.post(`${base_url}blogSubcategory/`, subcategory, config);
    return response.data;
};

const getBlogSubCategory = async (id) => {
    const response = await axios.get(`${base_url}blogSubcategory/${id}`, config);
    return response.data;
};

const deleteBlogSubCategory = async (id) => {
    const response = await axios.delete(`${base_url}blogSubcategory/${id}`, config);
    return response.data;
};

const updateBlogSubCategory = async (subcategory) => {
    const response = await axios.put(
        `${base_url}blogSubcategory/${subcategory.id}`,
        { title: subcategory.pSubCatData.title },
        config
    );
    return response.data;
};

const bSubCategoryService = {
    getBlogSubCategories,
    createSubCategory,
    getBlogSubCategory,
    deleteBlogSubCategory,
    updateBlogSubCategory,
};

export default bSubCategoryService;
