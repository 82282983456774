import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
// Create delivery boy
const createDeliveryBoy = async (data) => {
  const response = await axios.post(`${base_url}delivery/users`, data, config);
  return response.data;
};

// Fetch all delivery boys
const getDeliveryBoys = async () => {
  const response = await axios.get(`${base_url}delivery/delivery-boys`, config);
  return response.data;
};

// Update delivery boy
const updateDeliveryBoy = async (data) => {
  const response = await axios.put(`${base_url}delivery/delivery-boys/${data.id}`, data, config);
  return response.data;
};

// Delete delivery boy
const deleteDeliveryBoy = async (id) => {
  await axios.delete(`${base_url}delivery/delivery-boys/${id}`, config);
};

const deliveryService = {
  createDeliveryBoy,
  getDeliveryBoys,
  updateDeliveryBoy,
  deleteDeliveryBoy,
};

export default deliveryService;
