import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const uploadImg = async (data) => {
  try {
    const response = await axios.post(
      `${base_url}upload/create`,
      data,
      {
        ...config,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 600000, // 10 minutes
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status code outside the 2xx range
      console.error("Server Error:", error.response.data);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Network Error or Timeout:", error.message);
    } else {
      // Something else happened in setting up the request
      console.error("Error:", error.message);
    }
    throw error; // Rethrow the error so it can be handled by the caller
  }
};

const deleteImg = async (id) => {
  try {
    const response = await axios.delete(
      `${base_url}upload/delete-img/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting image:", error.message);
    throw error;
  }
};

const uploadService = {
  uploadImg,
  deleteImg,
};

export default uploadService;
