// src/features/resetPassword/resetPasswordService.js
import axios from 'axios';
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const resetPassword = async (token, currentPassword, newPassword) => {
    console.log(currentPassword)
  const response = await axios.put(`${base_url}user/changepassword/${token}`, {
    token,
    currentPassword,
    newPassword,
  },config);

  return response.data; 
};

const resetPasswordService ={
  resetPassword,
};
export default resetPasswordService;