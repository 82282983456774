// src/features/register/registerService.js
import axios from 'axios';
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const register = async (userData) => {
    console.log(userData)
  const response = await axios.post(`${base_url}user/register`, userData,config);
  return response.data;
};

const registerService = {
  register,
};

export default registerService;
