import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getTags = async () => {
    const response = await axios.get(`${base_url}tag/`);
    return response.data;
};

const createTag = async (tags) => {
    const response = await axios.post(`${base_url}tag/`, tags, config);
    return response.data;
};

const getTag = async (id) => {
    const response = await axios.get(`${base_url}tag/${id}`, config);
    return response.data;
};

const deleteTag = async (id) => {
    const response = await axios.delete(`${base_url}tag/${id}`, config);
    return response.data;
};

const updateTag = async (tag) => {
    const response = await axios.put(
        `${base_url}tag/${tag.id}`,
        { title: tag.tagData.title },
        config
    );
    return response.data;
};

const tagService = {
    getTags,
    createTag,
    getTag,
    deleteTag,
    updateTag,
};

export default tagService;
