import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import deliveryService from "./deliveryService"; // Import the service for API calls
import { toast } from "react-toastify";

// Create a delivery boy
export const createDeliveryBoy = createAsyncThunk(
  "deliveryBoy/create",
  async (data, thunkAPI) => {
    try {
      const response = await deliveryService.createDeliveryBoy(data);
      toast.success("Delivery Boy Created Successfully");
      return response;
    } catch (error) {
      toast.error("Error creating delivery boy");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch all delivery boys
export const fetchDeliveryBoys = createAsyncThunk(
  "deliveryBoy/fetchAll",
  async (_, thunkAPI) => {
    try {
      return await deliveryService.getDeliveryBoys();
    } catch (error) {
      toast.error("Error fetching delivery boys");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Update delivery boy
export const updateDeliveryBoy = createAsyncThunk(
  "deliveryBoy/update",
  async (data, thunkAPI) => {
    try {
      const response = await deliveryService.updateDeliveryBoy(data);
      toast.success("Delivery Boy Updated Successfully");
      return response;
    } catch (error) {
      toast.error("Error updating delivery boy");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Delete delivery boy
export const deleteDeliveryBoy = createAsyncThunk(
  "deliveryBoy/delete",
  async (id, thunkAPI) => {
    try {
      await deliveryService.deleteDeliveryBoy(id);
      toast.success("Delivery Boy Deleted Successfully");
      return id;
    } catch (error) {
      toast.error("Error deleting delivery boy");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deliveryBoySlice = createSlice({
  name: "deliveryBoy",
  initialState: {
    deliveryBoys: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDeliveryBoy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDeliveryBoy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deliveryBoys.push(action.payload);
      })
      .addCase(createDeliveryBoy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload || "Error creating delivery boy";
      })
      .addCase(fetchDeliveryBoys.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDeliveryBoys.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deliveryBoys = action.payload;
      })
      .addCase(fetchDeliveryBoys.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload || "Error fetching delivery boys";
      })
      .addCase(updateDeliveryBoy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDeliveryBoy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.deliveryBoys.findIndex((boy) => boy.id === action.payload.id);
        if (index !== -1) {
          state.deliveryBoys[index] = action.payload;
        }
      })
      .addCase(updateDeliveryBoy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload || "Error updating delivery boy";
      })
      .addCase(deleteDeliveryBoy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDeliveryBoy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deliveryBoys = state.deliveryBoys.filter((boy) => boy.id !== action.payload);
      })
      .addCase(deleteDeliveryBoy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload || "Error deleting delivery boy";
      });
  },
});

export default deliveryBoySlice.reducer;

