import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const getProducts = async () => {
  const response = await axios.get(`${base_url}product/`);
  console.log(response)

  return response.data;
};
const createProduct = async (product) => {
  const response = await axios.post(`${base_url}product/`, product, config);

  return response.data;
};

const getProduct = async (id) => {
  const response = await axios.get(`${base_url}product/${id}`, config);
 

  return response.data;
};


const getStoreProducts = async (storeId) => {

  const response = await axios.get(`${base_url}product/store/${storeId}`, config);
  console.log(response.data)
  return response.data;
};



const updateProduct = async (product) => {
  const response = await axios.put(
    `${base_url}product/${product.id}`,
    {
      title: product.productData.title,
      description: product.productData.description,
      price: product.productData.price,
      brand: product.productData.brand,
      quantity: product.productData.quantity,
      category: product.productData.category,
      tags: product.productData.tags,
      color: product.productData.color,
      images: product.productData.images,
      status: product.productData.status,
    },
    config
  );

  return response.data;
};

const updateProductStatus = async (product) => {
  const response = await axios.put(
    `${base_url}product/${product.id}`,
    {
      status: product.status,
    },
    config
  );

  return response.data;
};



const deleteproduct = async (id) => {
  const response = await axios.delete(`${base_url}product/${id}`, config);

  return response.data;
};
const fetchDashboardData = async (merchantId) => {
  try {
    const response = await axios.get(`${base_url}product/MerchantdashBoard/${merchantId}`);

    return response.data;  
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

const  fetchRecentProducts = async (merchantId) => {
  try {
    const response = await axios.get(`${base_url}product/recent/${merchantId}`);
    console.log("fetchRecentProducts ",response.data);
     return response.data
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};


const  getEachMechantProduct = async (merchantId) => {
  console.log(merchantId)
  try {
    const response = await axios.get(`${base_url}product/AllProduct/${merchantId}`);
   console.log(response.data)
    return response.data;  
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

const SuperAdminfetchDashboardData = async () => {
  try {
    const response = await axios.get(`${base_url}product/MerchantdashBoard`);
    console.log(response.data)
    return response.data;  
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

const  SuperAdminfetchRecentProducts = async () => {
  try {
    const response = await axios.get(`${base_url}product/recent`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};



const productService = {
  getProducts,
  createProduct,
  deleteproduct,
  updateProduct,
  getProduct,
  updateProductStatus,
  getStoreProducts,
  SuperAdminfetchRecentProducts,
  SuperAdminfetchDashboardData,
  fetchDashboardData,
  fetchRecentProducts,
  getEachMechantProduct,
  
};

export default productService;
