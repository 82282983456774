import React from "react";
import { Link } from "react-router-dom";

function FooterB() {
  return (
    <div>
      <div className="offcanvas__stikcy--toolbar">
        <ul className="d-flex justify-content-between">
          <li className="offcanvas__stikcy--toolbar__list">
            <Link className="offcanvas__stikcy--toolbar__btn" to="/delivery">
              <span className="offcanvas__stikcy--toolbar__icon center-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  width="21.51"
                  height="21.443"
                  viewBox="0 0 22 17"
                >
                  <path
                    fill="currentColor"
                    d="M20.9141 7.93359c.1406.11719.249.26953.249.45703 0 .14063-.0469.25782-.1406.35157l-.3516.42187c-.1172.14063-.2578.2494-.4219.2494-.1406 0-.2578-.04688-.3515-.14062l-.9844-.77344V15c0 .3047-.1172.5625-.3516.7734-.249.2344-.4687.3516-.7734.3516h-4.5c-.3047 0-.5742-.1172-.8086-.3516-.249-.249-.3164-.4687-.3164-.7734v-3.6562h-2.25V15c0 .3047-.11719.5625-.35156.7734-.2494.2344-.46875.3516-.77344.3516h-4.5c-.30469 0-.57422-.1172-.80859-.3516-.2494-.249-.31641-.4687-.31641-.7734V8.46094l-.94922.77344c-.11719.09374-.24609.14062-.38672.14062-.16406 0-.30468-.07031-.42187-.2494l-.35157-.42187C.921875 8.625.875 8.50781.875 8.39062c0-.1875.070312-.33984.2494-.45703L9.73438.832031C4.494.527344 4.5312.375 11 .375s.8906.152344 1.2656.457031l8.6485 7.41559zm-3.7266 6.50391V7.05469L11 1.99219l-6.1875 5.0625v7.38281h3.375v-3.6563c0-.3046.4547-.5624.31641-.7734.23437-.23436.5039-.35155.80859-.35155h3.375c.3047 0 .5625.11719.7734.35155.2344.211.3516.4688.3516.7734v3.6563h3.375z"
                  />
                </svg>
              </span>
              <span className="offcanvas__stikcy--toolbar__label">Home</span>
            </Link>
          </li>
          <li className="offcanvas__stikcy--toolbar__list">
            <Link className="offcanvas__stikcy--toolbar__btn" to="/delivery/orders">
              <span className="offcanvas__stikcy--toolbar__icon center-svg">
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.51"
                  height="17.443"
                  viewBox="0 0 448 512"
                >
                  <path d="M416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-16 48v152H248V80zm-200 0v152H48V80zM48 432V280h152v152zm200 0V280h152v152z" />
                </svg>
              </span>
              <span className="offcanvas__stikcy--toolbar__label">Orders</span>
            </Link>
          </li>
          <li className="offcanvas__stikcy--toolbar__list">
            <Link className="offcanvas__stikcy--toolbar__btn" to="/delivery/history">
              <span className="offcanvas__stikcy--toolbar__icon center-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    fill="currentColor"
                    d="M12 1a11 11 0 1 0 11 11A11.012 11.012 0 0 0 12 1zm1 17a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-3z"
                  />
                </svg>
              </span>
              <span className="offcanvas__stikcy--toolbar__label">History</span>
            </Link>
          </li>
          <li className="offcanvas__stikcy--toolbar__list">
            <Link
              className="offcanvas__stikcy--toolbar__btn"
              to="/delivery/proflle"
            >
              <span className="offcanvas__stikcy--toolbar__icon center-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    fill="currentColor"
                    d="M12 12a5 5 0 1 0-5-5 5 5 0 0 0 5 5zm0 2c-3.3 0-9 1.67-9 5v2h18v-2c0-3.33-5.7-5-9-5z"
                  />
                </svg>
              </span>
              <span className="offcanvas__stikcy--toolbar__label">Profile</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FooterB;
