// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from './UserListService'; // Rename service to a generic name (like `UserService`)
import { toast } from "react-toastify";

// Async thunk to fetch all users by role
export const getUsers = createAsyncThunk(
  'user/getAll',
  async (role, thunkAPI) => { // Accept `role` as an argument
    try {
      return await userService.getUsers(role); // Pass the role to the service
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to fetch a single user by ID
export const getUserById = createAsyncThunk(
  'user/getById',
  async ({ userId, role }, thunkAPI) => {
    try {
      return await userService.getUserById(userId, role); // Pass the role to the service
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update user status
export const updateUserStatus = createAsyncThunk(
  'user/updateStatus',
  async ({ id, status, role }, thunkAPI) => { // Add `role` as a parameter
    try {
      return await userService.updateUserStatus(id, status, role); // Pass the role to the service
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to delete a user
export const deleteUser = createAsyncThunk(
  'user/delete',
  async ({ userId, role }, thunkAPI) => { // Add `role` as a parameter
    try {
      await userService.deleteUser(userId, role); // Pass the role to the service
      return userId; // Return ID to update local state on successful deletion
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUserCount = createAsyncThunk(
  "user/getUserCount",
  async (_, thunkAPI) => {
    try {
      // Fetch user count data from authService
      const response = await userService.getUserCount();
      if (!response || typeof response !== "object") {
        throw new Error("Invalid response from the server");
      }
      return response; // Assuming response is the user count data object
    } catch (error) {
      // Enhanced error handling
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Failed to fetch user count";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const userSlice = createSlice({
  name: 'userList',
  initialState: {
    users: [],
    isLoading: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle getUsers
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userLists = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload || "Failed to fetch users";
      })
      // Handle getUserById if you want to display single user details
      .addCase(getUserById.fulfilled, (state, action) => {
        state.users = state.users.map((user) =>
          user.userId === action.payload.userId ? action.payload : user
        );
      })
      // Handle updateUserStatus
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        const updatedUserIndex = state.users.findIndex(
          (user) => user.userId === action.payload.userId
        );
        if (updatedUserIndex !== -1) {
          state.users[updatedUserIndex].status = action.payload.status;
        }
      })
      // Handle deleteUser
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter(
          (user) => user.userId !== action.payload
        );
      })
      .addCase(getUserCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserCount.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.userCount = action.payload;
        toast.success("User count retrieved successfully!");
        state.message = "User count retrieved successfully!";
      })
      .addCase(getUserCount.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
        toast.error(action.payload || "Error fetching user count");
        state.message = action.payload || "Error fetching user count";
      });
  },
});

export default userSlice.reducer;
