import React from 'react';
import HeaderT from "./HeaderT";
import FooterB from "./FooterB";
function Ddashboard() {
  return (
    <div>
     <HeaderT />
      <h1>Delivery Dashboard</h1>
      <FooterB />
    
    </div>
  );
}

export default Ddashboard;
