import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import reportService from "./reportService";

// Define asynchronous actions
export const createReport = createAsyncThunk(
  "report/create-report",
  async (reportData, thunkAPI) => {
    try {
      return await reportService.createReport(reportData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getReports = createAsyncThunk(
  "report/get-reports",
  async (thunkAPI) => {
    try {
      return await reportService.getReports();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAReport = createAsyncThunk(
  "report/get-report",
  async (id, thunkAPI) => {
    try {
      return await reportService.getReport(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateReport = createAsyncThunk(
  "report/update-report",
  async (reportData, thunkAPI) => {
    try {
      return await reportService.updateReport(reportData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteReport = createAsyncThunk(
  "report/delete-report",
  async (id, thunkAPI) => {
    try {
      return await reportService.deleteReport(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Action to reset the state
export const resetState = createAction("Reset_all");

// Initial state for the report feature
const initialState = {
    isSuccess: false,
    isError: false,
    isLoading: false,
    createdIssue: null,
    updatedIssue: null,
  };

// Report slice to handle actions and state
export const reportIssueSlice = createSlice({
  name: "issue",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Report created successfully!";
      })
      .addCase(createReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(getReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.reports = action.payload;
      })
      .addCase(getReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(getAReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.selectedReport = action.payload;  // Store the selected report data
      })
      .addCase(getAReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(updateReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Report updated successfully!";
      })
      .addCase(updateReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(deleteReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Report deleted successfully!";
      })
      .addCase(deleteReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(resetState, () => initialState); // Reset the state to its initial value
  },
});

export default reportIssueSlice.reducer;
