import axios from "axios";
import { config } from "../../utils/axiosconfig";  // Assuming you have an axios config file
import { base_url } from "../../utils/baseUrl";  // Assuming you have a base URL defined

// Fetch all documents
const getDocuments = async () => {
  const response = await axios.get(`${base_url}document/`, config);
  console.log(response.data)
  return response.data;
};

// Create a new document
const createDocument = async (document) => {
    
  const response = await axios.post(`${base_url}document/`, document, config);
  return response.data;
};

// Fetch a specific document by ID
const getDocument = async (id) => {
  const response = await axios.get(`${base_url}document/${id}`, config);
  return response.data;
};



// Delete a document
const deleteDocument = async (id) => {
  const response = await axios.delete(`${base_url}document/${id}`, config);
  return response.data;
};

// Update document status
const updateDocument = async (document) => {
  console.log(document)
  const response = await axios.put(
    `${base_url}document/${document.id}`,
    {
      status: document.status,
    },
    config
  );
  console.log(response.data)
  return response.data;
};

const documentService = {
  getDocuments,
  createDocument,
  getDocument,
  deleteDocument,
  updateDocument,
};

export default documentService;
