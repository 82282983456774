// userService.js
import axios from 'axios';
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

// Fetch all users by role
const getUsers = async (role) => {
  const response = await axios.get(`${base_url}user/userList?role=${role}`, config); // Use query parameter
  return response.data;
};


// Fetch a single user by ID and role
const getUserById = async (userId, role) => {
  const response = await axios.get(`${base_url}user//${userId}`, config); // Use role in the URL
  return response.data;
};

// Create a new user for a specified role (if needed)
const createUser = async (userData, role) => {
  const response = await axios.post(`${base_url}user/${role}`, userData, config); // Use role in the URL
  return response.data;
};

// Update user status or details based on role
const updateUserStatus = async (userId, status, role) => {
  const response = await axios.put(`${base_url}user/${role}/${userId}/status`, { status }, config); // Use role in the URL
  return response.data;
};

// Delete a user based on role
const deleteUser = async (userId, role) => {
  const response = await axios.delete(`${base_url}user/${role}/${userId}`, config); // Use role in the URL
  return response.data;
};
const getUserCount = async () => {
  const response = await axios.get(`${base_url}user/userCounts`, config);
  console.log(response.data)
  return response.data;
};
// Export all functions as userService
const userService = {
  getUsers,
  getUserById,
  createUser,
  updateUserStatus,
  deleteUser,
  getUserCount,
};

export default userService;
