import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderT from "./HeaderT";
import FooterB from "./FooterB";
import {
  fetchAssignedOrders,
  updateOrderStatus,
} from "../../features/orders/orderSlice";
import { QRCodeCanvas } from "qrcode.react";

const Orders = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const deliveryPersonId = user?._id;

  const { orders, isLoading, isError, message } = useSelector(
    (state) => state.orders
  );
  console.log("orders", orders);

  useEffect(() => {
    if (deliveryPersonId) {
      dispatch(fetchAssignedOrders(deliveryPersonId));
    }
  }, [dispatch, deliveryPersonId]);

  const handleChangeStatus = async (orderId) => {
    await dispatch(updateOrderStatus({ orderId, status: "active" }));
    // Refetch orders after updating status
    dispatch(fetchAssignedOrders(deliveryPersonId));
  };

  if (isLoading) {
    return <div className="text-center text-gray-500">Loading...</div>;
  }

  if (isError) {
    return (
      <div className="text-center text-red-500">
        {message || "Failed to fetch assigned orders."}
      </div>
    );
  }

  return (
    <div>
      <HeaderT />
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-5">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-4xl mx-4 md:mx-auto">
          <h3 className="text-center text-2xl font-bold mb-4">
            Assigned Orders
          </h3>
          {orders.length === 0 ? (
            <div className="text-center text-gray-600">
              No assigned orders yet.
            </div>
          ) : (
            <ul className="space-y-4">
              {orders.map((order) => (
                <li key={order._id} className="border rounded-lg p-4 shadow-md">
                  <h4 className="font-bold">Order ID: {order._id}</h4>
                  <p>
                    <strong>Address:</strong> {order.address}
                  </p>
                  <p>
                    <strong>City:</strong> {order.city}
                  </p>
                  <p>
                    <strong>Country:</strong> {order.country}
                  </p>
                  <p>
                    <strong>Status:</strong> {order.orderStatus}
                  </p>
                  <p>
                    <strong>Total Price:</strong> ${order.totalPrice.toFixed(2)}
                  </p>
                  <p>
                    <strong>Created At:</strong>{" "}
                    {new Date(order.createdAt).toLocaleString()}
                  </p>

                  {/* Display QR code only if order status is active */}
                  {order.orderStatus === "active" && (
                    <div className="mt-4">
                      <QRCodeCanvas value={`order:${order._id}`} size={256} />
                      <p className="text-green-500 mt-2">
                        Order is active. Please scan the QR code to confirm
                        delivery.
                      </p>
                    </div>
                  )}

                  {/* Button to change the status to active */}
                  <button
                    onClick={() => handleChangeStatus(order._id)}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Mark as Active
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <FooterB />
    </div>
  );
};

export default Orders;
